import {Injectable} from '@angular/core';
import {APP_DI_CONFIG} from '../../app-config/app-config.constants';
import {IAppConfig} from '../../app-config/app-config.interface';
import {
    BlockedProductTypesService
} from "../../layout/motive/design-research/blocked-product-types/blocked-product-types.service";
import {ProductTypesService} from "../../layout/amazon/product-types/product-types.service";

export interface ApiRoute {
    url: string
    method: string
}

/**
 * Api routes collection
 */
export const API_ROUTES = {
    // Motive
    motiveAutocomplete:          {url: 'motives/autocomplete', method: 'GET'},
    motiveTargetGroupAgesUpdate: {url: 'motives/{id}/targetAge', method: 'PUT'},
    motiveSexUpdate:             {url: 'motives/{id}/sex', method: 'PUT'},
    motiveNameUpdate:            {url: 'motives/{id}/name', method: 'PUT'},
    motiveInternalNameUpdate:    {url: 'motives/{id}/internalName', method: 'PUT'},

    // Motive Sex
    motiveSexAll: {url: 'motives/sexes', method: 'GET'},

    // Product data import
    productDataImportCreateFormConfigurations:         {
        url:    'raw-products/data-import/validation-rules/create',
        method: 'GET'
    },
    productDataImportCreateFormConfigurationsOnUpdate: {
        url:    'raw-products/data-import/validation-rules/update/{id}',
        method: 'GET'
    },
    productDataImportValidate:                         {url: 'raw-products/data-import/validate', method: 'POST'},
    productDataImportValidateOnUpdate:                 {url: 'raw-products/data-import/validate/{id}', method: 'POST'},
    productDataImportCreate:                           {url: 'raw-products/data-import', method: 'POST'},
    productDataImportUpdate:                           {url: 'raw-products/data-import/{id}', method: 'PUT'},
    productDataImportUploadFile:                       {url: 'raw-products/data-import/{id}/file', method: 'POST'},
    productDataImportOne:                              {url: 'raw-products/data-import/{id}', method: 'GET'},
    productDataImportAll:                              {url: 'raw-products/data-import', method: 'GET'},

    // Shop product
    productProductsGetAll:                  {url: 'shop-products', method: 'GET'},
    productProductsGetOne:                  {url: 'shop-products/{id}', method: 'GET'},
    productProductsGetProductVariationList: {url: 'product-variations/{shopProductId}', method: 'GET'},
    productProductsGetFeedHistoryList:      {url: 'amazon/es-feed-queues', method: 'GET'},


    // Marketplace info
    productMarketplaceInfoGetList: {url: 'marketplace-info', method: 'GET'},

    // Goods tariff numbers
    goodsTariffNumbersUpdate:          {url: 'raw-products/goods-tariff-number/{id}', method: 'PUT'},
    goodsTariffNumbersCreate:          {url: 'raw-products/goods-tariff-number/', method: 'POST'},
    goodsTariffNumbersDelete:          {url: 'raw-products/goods-tariff-number/{id}', method: 'DELETE'},
    goodsTariffNumbersRelations:       {url: 'raw-products/goods-tariff-number/{id}/relations', method: 'GET'},
    goodsTariffNumbersAll:             {url: 'raw-products/goods-tariff-number', method: 'GET'},
    goodsTariffNumbersValidationRules: {url: 'raw-products/goods-tariff-number/validation-rules/{type}', method: 'GET'},

    // Sizes
    sizesUpdate:          {url: 'raw-products/sizes/{id}', method: 'PUT'},
    sizesUpdatePosition:  {url: 'raw-products/sizes/{id}/position/{position}', method: 'PUT'},
    sizesCreate:          {url: 'raw-products/sizes/', method: 'POST'},
    sizesDelete:          {url: 'raw-products/sizes/{id}', method: 'DELETE'},
    sizesAll:             {url: 'raw-products/sizes', method: 'GET'},
    sizesGetOne:          {url: 'raw-products/sizes/{id}', method: 'GET'},
    sizesValidationRules: {url: 'raw-products/sizes/validation-rules/{type}', method: 'GET'},

    sizeChannelSettingsAll:                 {url: 'raw-products/sizes/channel-settings', method: 'GET'},
    sizeChannelSettingsValidationRules:     {
        url:    'raw-products/sizes/channel-settings/validation-rules/{type}/channel/{channelId}',
        method: 'GET'
    },
    sizeChannelSettingsUpdate:              {url: 'raw-products/sizes/channel-settings/{id}', method: 'PUT'},
    sizeChannelSettingsCreate:              {url: 'raw-products/sizes/channel-settings/', method: 'POST'},
    sizeChannelSettingsOneBySizeAndChannel: {
        url:    'raw-products/sizes/channel-settings/size/{sizeId}/channel/{channelId}',
        method: 'GET'
    },

    // Size groups
    sizeGroupsUpdate:          {url: 'raw-products/size-groups/{id}', method: 'PUT'},
    sizeGroupsUpdatePosition:  {url: 'raw-products/size-groups/{id}/position/{position}', method: 'PUT'},
    sizeGroupsCreate:          {url: 'raw-products/size-groups/', method: 'POST'},
    sizeGroupsDelete:          {url: 'raw-products/size-groups/{id}', method: 'DELETE'},
    sizeGroupsAll:             {url: 'raw-products/size-groups', method: 'GET'},
    sizeGroupsGetOne:          {url: 'raw-products/size-groups/{id}', method: 'GET'},
    sizeGroupsValidationRules: {url: 'raw-products/size-groups/validation-rules/{type}', method: 'GET'},

    // Color groups
    colorGroupsAll:             {url: 'raw-products/color-groups', method: 'GET'},
    colorGroupsUpdate:          {url: 'raw-products/color-groups/{id}', method: 'PUT'},
    colorGroupsCreate:          {url: 'raw-products/color-groups/', method: 'POST'},
    colorGroupsDelete:          {url: 'raw-products/color-groups/{id}', method: 'DELETE'},
    colorGroupsGetOne:          {url: 'raw-products/color-groups/{id}', method: 'GET'},
    colorGroupsValidationRules: {url: 'raw-products/color-groups/validation-rules/{type}', method: 'GET'},

    colorGroupChannelSettingsOneByColorGroupAndChannel: {
        url:    'raw-products/color-groups/channel-settings/color-group/{colorGroupId}/channel/{channelId}',
        method: 'GET'
    },
    colorGroupChannelSettingsValidationRules:           {
        url:    'raw-products/color-groups/channel-settings/validation-rules/{type}/channel/{channelId}',
        method: 'GET'
    },
    colorGroupChannelSettingsCreate:                    {
        url:    'raw-products/color-groups/channel-settings/',
        method: 'POST'
    },
    colorGroupChannelSettingsUpdate:                    {
        url:    'raw-products/color-groups/channel-settings/{id}',
        method: 'PUT'
    },

    // Colors
    colorsAll:             {url: 'raw-products/colors', method: 'GET'},
    colorsUpdate:          {url: 'raw-products/colors/{id}', method: 'PUT'},
    colorsCreate:          {url: 'raw-products/colors/', method: 'POST'},
    colorsDelete:          {url: 'raw-products/colors/{id}', method: 'DELETE'},
    colorsValidationRules: {url: 'raw-products/colors/validation-rules/{type}', method: 'GET'},

    // Fulfillment provider
    fulfillmentProviderAll:              {url: 'fulfillment-providers', method: 'GET'},
    fulfillmentProviderUpdate:           {url: 'fulfillment-providers/{id}', method: 'PUT'},
    fulfillmentProviderValidationRules:  {url: 'fulfillment-providers/validation-rules/{type}', method: 'GET'},
    fulfillmentProviderValidateOnUpdate: {url: 'fulfillment-providers/validate/{id}', method: 'PUT'},

    // Fulfillment provider orders
    fulfillmentProviderOrdersAll:                      {url: 'fulfillment-provider-orders', method: 'GET'},
    fulfillmentProviderOrdersCreate:                   {url: 'fulfillment-provider-orders/create', method: 'POST'},
    fulfillmentProviderOrdersCreateClaim:              {
        url:    'fulfillment-provider-orders/{id}/create-claim',
        method: 'POST'
    },
    fulfillmentProviderOrdersByAllOrder:               {url: 'fulfillment-provider-orders/orders/{id}', method: 'GET'},
    fulfillmentProviderOrdersCancel:                   {url: 'fulfillment-provider-orders/{id}/cancel', method: 'GET'},
    fulfillmentProviderOrdersDownloadDeliveryDocument: {
        url:    'fulfillment-provider-orders/{id}/documents/delivery/download',
        method: 'GET'
    },
    fulfillmentProviderOrdersBlockProduction:          {
        url:    'fulfillment-provider-orders/{id}/block-production',
        method: 'PUT'
    },
    fulfillmentProviderOrdersReleaseProduction:        {
        url:    'fulfillment-provider-orders/{id}/release-production',
        method: 'PUT'
    },

    // Fulfillment provider printing file template
    fulfillmentProviderPrintingFileTemplateAll:             {
        url:    'fulfillment-provider-printing-file-templates',
        method: 'GET'
    },
    fulfillmentProviderPrintingFileTemplateCreate:          {
        url:    'fulfillment-provider-printing-file-templates',
        method: 'POST'
    },
    fulfillmentProviderPrintingFileTemplateUpdate:          {
        url:    'fulfillment-provider-printing-file-templates/{id}',
        method: 'PUT'
    },
    fulfillmentProviderPrintingFileTemplateDelete:          {
        url:    'fulfillment-provider-printing-file-templates/{id}',
        method: 'DELETE'
    },
    fulfillmentProviderPrintingFileTemplateValidationRules: {
        url:    'fulfillment-provider-printing-file-templates/validation-rules/{type}',
        method: 'GET'
    },
    fulfillmentProviderPrintingFileTemplatePreview:         {
        url:    'fulfillment-provider-printing-file-templates/{id}/preview?motiveId={motiveId}&bearer={token}',
        method: 'GET'
    },

    // Orders
    ordersAll:                                       {url: 'orders', method: 'GET'},
    orderGetStatistics:                              {url: 'orders/statistics', method: 'GET'},
    ordersNotShipped:                                {url: 'orders/not-shipped', method: 'GET'},
    orderGetOne:                                     {url: 'orders/{id}', method: 'GET'},
    orderLogsAll:                                    {url: 'orders/{id}/logs', method: 'GET'},
    orderCommentsAll:                                {url: 'orders/{id}/comments', method: 'GET'},
    orderCommentsCreate:                             {url: 'orders/{id}/comments', method: 'POST'},
    orderDocumentsAll:                               {url: 'orders/{id}/documents', method: 'GET'},
    orderUpdateDocumentPdf:                          {url: 'orders/{orderId}/documents/{id}/update-pdf', method: 'PUT'},
    orderShippingsAll:                               {url: 'orders/{id}/shippings', method: 'GET'},
    orderOrderItemsAll:                              {url: 'orders/{id}/order-items', method: 'GET'},
    orderOrderItemCustomImage:                       {url: 'order-items/custom-image/{id}', method: 'GET'},
    orderCancel:                                     {url: 'orders/{id}/cancel', method: 'PUT'},
    orderShippingGroupValidationRules:               {
        url:    'orders/{id}/shipping/validation-rules/update',
        method: 'GET'
    },
    orderShippingUpdate:                             {url: 'orders/{id}/shipping', method: 'PUT'},
    orderInfo:                                       {url: 'orders/{id}/info', method: 'GET'},
    orderItemProductVariationEditFormConfigurations: {
        url:    'order-items/product-variations/{id}/validation',
        method: 'GET'
    },

    // Sexes
    sexesAll:              {url: 'raw-products/sexes', method: 'GET'},
    sexesUpdate:           {url: 'raw-products/sexes/{id}', method: 'PUT'},
    sexesCreate:           {url: 'raw-products/sexes/', method: 'POST'},
    sexesDelete:           {url: 'raw-products/sexes/{id}', method: 'DELETE'},
    sexesGetOne:           {url: 'raw-products/sexes/{id}', method: 'GET'},
    sexesUpdatePosition:   {url: 'raw-products/size-groups/{id}/position/{position}', method: 'PUT'},
    sexesValidationRules:  {url: 'raw-products/sexes/validation-rules/{type}', method: 'GET'},
    sexesValidateOnCreate: {url: 'raw-products/sexes/validate', method: 'POST'},
    sexesValidateOnUpdate: {url: 'raw-products/sexes/validate/{id}', method: 'PUT'},
    sexesSyncToAmazon:     {url: 'raw-products/sexes/sync-to-amazon/{id}', method: 'PUT'},

    // Sex old urls
    sexOldUrlsList:   {url: 'raw-products/sex-old-urls/{sexId}', method: 'GET'},
    sexOldUrlsCreate: {url: 'raw-products/sex-old-urls/{sexId}', method: 'POST'},
    sexOldUrlsUpdate: {url: 'raw-products/sex-old-urls/{sexId}/oldurls/{oldUrlId}', method: 'PUT'},
    sexOldUrlsDelete: {url: 'raw-products/sex-old-urls/{sexId}/oldurls/{oldUrlId}', method: 'DELETE'},

    // Mockups
    mockupsUpdate:                {url: 'mockups/{id}', method: 'PUT'},
    mockupsCreate:                {url: 'mockups/', method: 'POST'},
    mockupsDelete:                {url: 'mockups/{id}', method: 'DELETE'},
    mockupsAll:                   {url: 'mockups', method: 'GET'},
    mockupsOne:                   {url: 'mockups/{id}', method: 'GET'},
    mockupsValidationRules:       {url: 'mockups/validation-rules/{type}', method: 'GET'},
    mockupsValidationRulesUpdate: {url: 'mockups/validation-rules/{type}/{id}', method: 'GET'},
    mockupsValidateOnCreate:      {url: 'mockups/validate', method: 'POST'},
    mockupsValidateOnUpdate:      {url: 'mockups/validate/{id}', method: 'PUT'},
    mockupsValidateOnRelease:     {url: 'mockups/release/validate', method: 'POST'},

    // Mockup Previews
    mockupPreviewsAll:              {url: 'mockups/{id}/previews', method: 'GET'},
    mockupPreviewsCreate:           {url: 'mockups/{id}/preview', method: 'POST'},
    mockupPreviewsValidateOnCreate: {url: 'mockups/{id}/previews/validate', method: 'POST'},
    mockupPreviewsInfo:             {url: 'mockups/{id}/preview-info', method: 'GET'},

    // Mockup Usage Condition
    mockupUsageConditionsUpdate:           {url: 'mockups/mockup-usage-conditions/{id}', method: 'PUT'},
    mockupUsageConditionsCreate:           {url: 'mockups/mockup-usage-conditions/', method: 'POST'},
    mockupUsageConditionsDelete:           {url: 'mockups/mockup-usage-conditions/{id}', method: 'DELETE'},
    mockupUsageConditionsAll:              {url: 'mockups/mockup-usage-conditions/channels/{channelId}', method: 'GET'},
    mockupUsageConditionsOne:              {url: 'mockups/mockup-usage-conditions/{id}', method: 'GET'},
    mockupUsageConditionsValidationRules:  {
        url:    'mockups/mockup-usage-conditions/validation-rules/{type}/channels/{channelId}',
        method: 'GET'
    },
    mockupUsageConditionsValidateOnCreate: {url: 'mockups/mockup-usage-conditions/validate', method: 'POST'},
    mockupUsageConditionsValidateOnUpdate: {url: 'mockups/mockup-usage-conditions/validate/{id}', method: 'PUT'},
    mockupUsageConditionsUpdatePosition:   {
        url:    'mockups/mockup-usage-conditions/{id}/position/{position}',
        method: 'PUT'
    },

    // Mockup Layer Image
    mockupLayerImageUpload: {url: 'mockups/{mockupId}/layer-images/layers/{layerId}/upload-image', method: 'POST'},
    mockupLayerImageDelete: {url: 'mockups/{mockupId}/layer-images/layers/{layerId}', method: 'DELETE'},

    // Raw Products
    rawProductAutocomplete:                        {url: 'raw-products/autocomplete', method: 'GET'},
    rawProductOne:                                 {url: 'raw-products/{id}', method: 'GET'},
    rawProductSizes:                               {url: 'raw-products/{id}/sizes', method: 'GET'},
    rawProductUpdateInventoryFieldsConfigurations: {
        url:    'raw-products/{id}/validation-rules/inventory-fields',
        method: 'GET'
    },
    rawProductUpdateInventoryFields:               {url: 'raw-products/{id}/inventory-fields', method: 'PUT'},
    rawProductsAll:                                {url: 'raw-products', method: 'GET'},
    rawProductColors:                              {
        url:    'raw-products/colors/raw-product/{rawProductId}',
        method: 'GET'
    },
    rawProductProductTypeColor:                    {
        url:    'amazon/product-types/colors/one',
        method: 'GET'
    },
    rawProductsUpdateProductTypeColorMapping:      {
        url:    'amazon/product-types/colors/',
        method: 'PUT'
    },
    rawProductProductTypeMeasure:                  {
        url:    'amazon/product-types/measures/one',
        method: 'GET'
    },
    rawProductsUpdateProductTypeMeasureMapping:    {
        url:    'amazon/product-types/measures/',
        method: 'PUT'
    },
    rawProductMeasures:                            {
        url:    'measures/raw-product/{rawProductId}',
        method: 'GET'
    },
    rawProductGetChannelSetting:                   {
        url:    'raw-products/channel-setting/raw-product/{rawProductId}/channel/{channelId}',
        method: 'GET'
    },
    rawProductCreateChannelSetting:                {url: 'raw-products/channel-setting/', method: 'POST'},
    rawProductUpdateChannelSetting:                {url: 'raw-products/channel-setting/{id}', method: 'PUT'},
    rawProductUpdatePlatformCategoryData:          {url: 'raw-products/platform-category-data/{id}', method: 'PUT'},
    rawProductsPlatformCategoryDataOne:            {url: 'raw-products/platform-category-data/{id}', method: 'GET'},
    rawProductsPlatformCategoryDataDelete:         {url: 'raw-products/platform-category-data/{id}', method: 'DELETE'},
    rawProductsCreatePlatformCategoryData:         {url: 'raw-products/platform-category-data/', method: 'POST'},
    rawProductsGetListByRawProductId:              {
        url:    'raw-products/platform-category-data/raw-product/{rawProductId}/platform/{platformId}',
        method: 'GET'
    },
    rawProductsGetProductTypesByRawProductId:      {
        url:    'amazon/product-types/raw-products/',
        method: 'GET'
    },
    rawProductsGetProductType:                     {
        url:    'amazon/product-types/raw-products/one',
        method: 'GET'
    },
    rawProductsUpdateProductTypeMapping:           {
        url:    'amazon/product-types/raw-products/',
        method: 'PUT'
    },
    rawProductsCreateProductType:                  {
        url:    'amazon/product-types/raw-products/{rawProductId}',
        method: 'POST'
    },
    rawProductsDeleteProductType:                  {
        url:    'amazon/product-types/raw-products/{id}',
        method: 'DELETE'
    },
    rawProductsGetRoq:                             {url: 'raw-products/{id}/roq', method: 'GET'},
    rawProductsUpdateRoq:                          {url: 'raw-products/{id}/roq', method: 'PUT'},
    rawProductsTargetGroupAgesUpdate:              {url: 'raw-products/{id}/targetAge', method: 'PUT'},
    rawProductsMotiveSexUpdate:                    {url: 'raw-products/{id}/sex', method: 'PUT'},
    rawProductsNamesUpdate:                        {url: 'raw-products/{id}/names', method: 'PUT'},
    rawProductsInventoryAll:                       {
        url:    'raw-products/inventories/raw-product/{rawProductId}',
        method: 'GET'
    },

    productTypeRawProductMapSetDefault: {
        url:    'amazon/product-types/raw-products/{id}/make-default',
        method: 'PUT'
    },

    // Fulfilment Inventory Map
    rawProductsFulfilmentInventoryMapAll:             {
        url:    'raw-products/fulfillment-provider-inventory-map/inventory/{inventoryId}',
        method: 'GET'
    },
    rawProductsFulfilmentInventoryMapCreate:          {
        url:    'raw-products/fulfillment-provider-inventory-map',
        method: 'POST'
    },
    rawProductsFulfilmentInventoryMapUpdate:          {
        url:    'raw-products/fulfillment-provider-inventory-map/{id}',
        method: 'PUT'
    },
    rawProductsFulfilmentInventoryMapValidationRules: {
        url:    'raw-products/fulfillment-provider-inventory-map/validation-rules/{type}/inventory/{inventoryId}',
        method: 'GET'
    },
    rawProductsFulfilmentInventoryMapUpdatePosition:  {
        url:    'raw-products/fulfillment-provider-inventory-map/{id}/position/{position}',
        method: 'PUT'
    },

    // Roq recipe
    roqRecipesAll:             {url: 'roq-recipes', method: 'GET'},
    roqRecipesUpdate:          {url: 'roq-recipes/{id}', method: 'PUT'},
    roqRecipesCreate:          {url: 'roq-recipes/', method: 'POST'},
    roqRecipesDelete:          {url: 'roq-recipes/{id}', method: 'DELETE'},
    roqRecipesValidationRules: {url: 'roq-recipes/validation-rules/{type}', method: 'GET'},

    // Measure
    measureAll:       {url: 'measures/', method: 'GET'},
    measureUpdateRoq: {url: 'measures/roq', method: 'PUT'},

    // Platforms
    platformsAll: {url: 'platforms', method: 'GET'},

    // Channels
    channelsAll:         {url: 'channels', method: 'GET'},
    channelsGetOne:      {url: 'channels/{id}', method: 'GET'},
    channelsGetSettings: {url: 'channels/{id}/settings', method: 'GET'},

    // Platform category
    platformCategoryAll:             {url: 'platforms/{platformId}/categories', method: 'GET'},
    platformCategoryValidationRules: {url: 'platforms/categories/validation-rules/{type}', method: 'GET'},
    platformCategoryUpdate:          {url: 'platforms/categories/{id}', method: 'PUT'},
    platformCategoryOne:             {url: 'platforms/categories/{id}', method: 'GET'},
    platformAttributeMappingList:    {url: 'platforms/attribute-mappings', method: 'GET'},

    // Motiv
    motiveAll: {url: 'motives', method: 'GET'},

    designResearchAll:          {url: 'design-research', method: 'GET'},
    designResearchGetOne:       {url: 'design-research/{id}', method: 'GET'},
    designResearchChangeReview: {url: 'design-research/{id}/review', method: 'PUT'},
    designResearchDeclineBatch: {url: 'design-research/decline/batch', method: 'PUT'},

    // BlockedProductTypes
    designResearchBlockedProductTypesAll:          {url: 'design-research/blocked-product-types', method: 'GET'},
    designResearchBlockedProductTypesDelete:       {
        url:    'design-research/blocked-product-types/{id}',
        method: 'DELETE'
    },
    designResearchBlockedProductTypesDeleteByName: {
        url:    'design-research/blocked-product-types/{name}',
        method: 'DELETE'
    },
    designResearchBlockedProductTypesCreate:       {url: 'design-research/blocked-product-types/', method: 'POST'},

    // Motive category
    motiveCategoryAll:                          {url: 'design/design-categories', method: 'GET'},
    motiveCategoryOne:                          {url: 'design/design-categories/{id}', method: 'GET'},
    motiveCategoryValidationRules:              {
        url:    'design/design-categories/validation-rules/{type}',
        method: 'GET'
    },
    motiveCategoryUpdate:                       {url: 'design/design-categories/{id}', method: 'PUT'},
    motiveCategoryNamesUpdate:                  {url: 'design/design-categories/{id}/names', method: 'PUT'},
    motiveCategoryGetListByCategoryAndPlatform: {
        url:    'design/design-categories/platform-category-data/motive-category/{motiveCategoryId}/platform/{platformId}',
        method: 'GET'
    },
    motiveCategoryGetListByPlatformCategory:    {
        url:    'design/design-categories/platform-category-data/platform-category/{platformCategoryId}',
        method: 'GET'
    },
    motiveCategoryPlatformCategoryDataDelete:   {
        url:    'design/design-categories/platform-category-data/{id}',
        method: 'DELETE'
    },
    motiveCategoryCreatePlatformCategoryData:   {
        url:    'design/design-categories/platform-category-data/',
        method: 'POST'
    },
    motiveCategoryUpdatePlatformCategoryData:   {
        url:    'design/design-categories/platform-category-data/{id}',
        method: 'PUT'
    },
    motiveCategoryPlatformCategoryDataOne:      {
        url:    'design/design-categories/platform-category-data/{id}',
        method: 'GET'
    },

    // Motive category channel setting
    motiveCategoryChannelSettingOneByMotiveCategoryAndChannel: {
        url:    'design/design-categories/channel-settings/motive-category/{motiveCategoryId}/channel/{channelId}',
        method: 'GET'
    },
    motiveCategoryChannelSettingValidationRules:               {
        url:    'design/design-categories/channel-settings/validation-rules/{type}/channel/{channelId}',
        method: 'GET'
    },
    motiveCategoryChannelSettingUpdate:                        {
        url:    'design/design-categories/channel-settings/{id}',
        method: 'PUT'
    },
    motiveCategoryChannelSettingCreate:                        {
        url:    'design/design-categories/channel-settings/',
        method: 'POST'
    },

    // Main material
    mainMaterialAll:             {url: 'main-materials', method: 'GET'},
    mainMaterialUpdate:          {url: 'main-materials/{id}', method: 'PUT'},
    mainMaterialCreate:          {url: 'main-materials/', method: 'POST'},
    mainMaterialDelete:          {url: 'main-materials/{id}', method: 'DELETE'},
    mainMaterialValidationRules: {url: 'main-materials/validation-rules/{type}', method: 'GET'},

    // Settings
    settingsAll:    {url: 'settings', method: 'GET'},
    settingsUpdate: {url: 'settings', method: 'PUT'},

    // Response code
    responseMessageCodeUpdate:          {url: 'response-code/messages/{id}', method: 'PUT'},
    responseMessageCodeAll:             {url: 'response-code/messages', method: 'GET'},
    responseMessageCodeValidationRules: {url: 'response-code/messages/validation-rules/{type}', method: 'GET'},

    // AmazonRestrictedTitlePhrases
    amazonRestrictedTitlePhrasesAll:             {url: 'amazon/restricted-title-phrases', method: 'GET'},
    amazonRestrictedTitlePhrasesDelete:          {url: 'amazon/restricted-title-phrases/{id}', method: 'DELETE'},
    amazonRestrictedTitlePhrasesUpdate:          {url: 'amazon/restricted-title-phrases/{id}', method: 'PUT'},
    amazonRestrictedTitlePhrasesCreate:          {url: 'amazon/restricted-title-phrases/', method: 'POST'},
    amazonRestrictedTitlePhrasesRelations:       {url: 'amazon/restricted-title-phrases/{id}/relations', method: 'GET'},
    amazonRestrictedTitlePhrasesValidationRules: {
        url:    'amazon/restricted-title-phrases/validation-rules/{type}',
        method: 'GET'
    },
    amazonRestrictedTitlePhrasesMarkAsReviewed:  {url: 'amazon/restricted-title-phrases/{id}/review', method: 'PATCH'},

    // Search Terms
    searchTermsAll:             {url: 'amazon/advertising/search-terms', method: 'GET'},
    searchTermsDelete:          {url: 'amazon/advertising/search-terms/{id}', method: 'DELETE'},
    searchTermsUpdate:          {url: 'amazon/advertising/search-terms/{id}', method: 'PUT'},
    searchTermsCreate:          {url: 'amazon/advertising/search-terms', method: 'POST'},
    searchTermsRelations:       {url: 'amazon/advertising/search-terms/{id}/relations', method: 'GET'},
    searchTermsValidationRules: {url: 'amazon/advertising/search-terms/validation-rules/{type}', method: 'GET'},
    searchTermsMarkAsReviewed:  {url: 'amazon/advertising/search-terms/{id}/review', method: 'PATCH'},

    // General Search Terms
    generalSearchTermsAll:                      {url: 'general/advertising/search-terms', method: 'GET'},
    generalSearchTermsDelete:                   {url: 'general/advertising/search-terms/{id}', method: 'DELETE'},
    generalSearchTermsUpdate:                   {url: 'general/advertising/search-terms/{id}', method: 'PUT'},
    generalSearchTermsCreate:                   {url: 'general/advertising/search-terms', method: 'POST'},
    generalSearchTermsRelations:                {url: 'general/advertising/search-terms/{id}/relations', method: 'GET'},
    generalSearchTermsValidationRules:          {
        url:    'general/advertising/search-terms/validation-rules/{type}',
        method: 'GET'
    },
    generalSearchTermsMarkAsReviewed:           {url: 'general/advertising/search-terms/{id}/review', method: 'PATCH'},
    generalTrademarksGetDataForSearchTerm:      {
        url:    'amazon/advertising/trademarks/search-term/{searchTermId}/general',
        method: 'GET'
    },
    generalTrademarksUpdateWithSearchTerm:      {
        url:    'amazon/advertising/trademarks/{id}/{searchTermId}/general',
        method: 'PUT'
    },
    generalTrademarksCheckSearchTermTrademarks: {
        url:    'amazon/advertising/trademarks/search-term-check/{searchTermId}/general',
        method: 'GET'
    },

    // tag Search Terms
    tagSearchTermsAll:                      {url: 'tag/advertising/search-terms', method: 'GET'},
    tagSearchTermsDelete:                   {url: 'tag/advertising/search-terms/{id}', method: 'DELETE'},
    tagSearchTermsUpdate:                   {url: 'tag/advertising/search-terms/{id}', method: 'PUT'},
    tagSearchTermsCreate:                   {url: 'tag/advertising/search-terms', method: 'POST'},
    tagSearchTermsRelations:                {url: 'tag/advertising/search-terms/{id}/relations', method: 'GET'},
    tagSearchTermsValidationRules:          {
        url:    'tag/advertising/search-terms/validation-rules/{type}',
        method: 'GET'
    },
    tagSearchTermsMarkAsReviewed:           {url: 'tag/advertising/search-terms/{id}/review', method: 'PATCH'},
    tagTrademarksGetDataForSearchTerm:      {
        url:    'amazon/advertising/trademarks/search-term/{searchTermId}/tag',
        method: 'GET'
    },
    tagTrademarksUpdateWithSearchTerm:      {
        url:    'amazon/advertising/trademarks/{id}/{searchTermId}/tag',
        method: 'PUT'
    },
    tagTrademarksCheckSearchTermTrademarks: {
        url:    'amazon/advertising/trademarks/search-term-check/{searchTermId}/tag',
        method: 'GET'
    },


    // Trademark
    trademarksAll:                       {url: 'amazon/advertising/trademarks', method: 'GET'},
    trademarksCheckString:               {url: 'amazon/advertising/trademarks/checkString', method: 'GET'},
    trademarksOne:                       {url: 'amazon/advertising/trademarks/{id}/{type}', method: 'GET'},
    trademarksGetDataForSearchTerm:      {
        url:    'amazon/advertising/trademarks/search-term/{searchTermId}/amazon',
        method: 'GET'
    },
    trademarksUpdateWithSearchTerm:      {
        url:    'amazon/advertising/trademarks/{id}/{searchTermId}/amazon',
        method: 'PUT'
    },
    trademarksUpdate:                    {url: 'amazon/advertising/trademarks/{id}/{type}', method: 'PUT'},
    trademarksCreate:                    {url: 'amazon/advertising/trademarks/', method: 'POST'},
    trademarksDelete:                    {url: 'amazon/advertising/trademarks/{id}', method: 'DELETE'},
    trademarkRelations:                  {url: 'amazon/advertising/trademarks/{id}/relations', method: 'GET'},
    trademarksValidationRules:           {url: 'amazon/advertising/trademarks/validation-rules/{type}', method: 'GET'},
    trademarksCheckSearchTermTrademarks: {
        url:    'amazon/advertising/trademarks/search-term-check/{searchTermId}/amazon',
        method: 'GET'
    },
    trademarksCheckTrademark:            {
        url:    'amazon/advertising/trademarks/trademark-check/{id}/{type}',
        method: 'GET'
    },
    trademarksAddComment:                {url: 'amazon/advertising/trademarks/{id}/review-comments', method: 'POST'},
    trademarksEditComment:               {
        url:    'amazon/advertising/trademarks/{id}/review-comments/{reviewCommentId}',
        method: 'PUT'
    },
    trademarksDeleteComment:             {
        url:    'amazon/advertising/trademarks/{id}/review-comments/{reviewCommentId}',
        method: 'DELETE'
    },

    // Search Term Reports
    searchTermReportsAll:             {url: 'amazon/advertising/reports', method: 'GET'},
    searchTermReportsCreate:          {url: 'amazon/advertising/reports/', method: 'POST'},
    searchTermReportsValidationRules: {url: 'amazon/advertising/reports/validation-rules/{type}', method: 'GET'},

    // Amazon review
    amazonReviewsAll:      {url: 'amazon/reviews', method: 'GET'},
    amazonReviewsComments: {url: 'amazon/reviews/{id}/comments', method: 'GET'},

    // Advertising data export
    advertisingDataExportAll:         {url: 'amazon/advertising/shop-product-advertising-export', method: 'GET'},
    advertisingDataExportDownloadXLS: {
        url:    'amazon/advertising/shop-product-advertising-export/download-xls',
        method: 'GET'
    },
    advertisingDataExportDetail:      {url: 'amazon/advertising/shop-product-advertising-export/{id}', method: 'GET'},

    // Sponsored brands campaign
    sponsoredBrandCampaignAll:    {url: 'amazon/advertising/sponsored-brand-campaign', method: 'GET'},
    sponsoredBrandCampaignUpdate: {url: 'amazon/advertising/sponsored-brand-campaign/{id}', method: 'PUT'},

    // Shipping groups
    shippingGroupsAll:       {url: 'amazon/shipping-groups', method: 'GET'},
    shippingGroupsUpdateAll: {url: 'amazon/shipping-groups', method: 'POST'},

    // Shipping groups
    productTypesAll:           {url: 'amazon/product-types', method: 'GET'},
    productTypesGetOne:        {url: 'amazon/product-types/{id}', method: 'GET'},
    productTypesCreate:        {url: 'amazon/product-types', method: 'POST'},
    productTypesUpdateMapping: {url: 'amazon/product-types/{id}/mapping', method: 'PUT'},
    productTypesAutocomplete:  {url: 'amazon/product-types/autocomplete', method: 'GET'},

    productTypesColorUpdateMapping: {url: 'amazon/product-types/colors/{id}', method: 'PUT'},

    // Feed queues
    feedQueueAll:             {url: 'amazon/feed-queues', method: 'GET'},
    feedQueueUpdate:          {url: 'amazon/feed-queues/{id}', method: 'PUT'},
    feedQueueValidationRules: {url: 'amazon/feed-queues/validation-rules/{type}/feedQueues/{id}', method: 'GET'},

    // Feed report error
    feedReportErrorAll: {url: 'amazon/feed-errors', method: 'GET'},

    // Blocked product variations
    blockedProductVariationAll:     {url: 'product-variations/blocked-on-amazon', method: 'GET'},
    blockedProductVariationBlock:   {url: 'product-variations/blocked-on-amazon/block/{id}', method: 'POST'},
    blockedProductVariationUnblock: {url: 'product-variations/blocked-on-amazon/unblock/{id}', method: 'POST'},

    // Feed test tool
    feedTestToolGetShopProducts: {url: 'amazon/feed-test-tool/shop-products', method: 'GET'},
    feedTestToolGenerateXml:     {url: 'amazon/feed-test-tool/generate-xml', method: 'POST'},
    feedTestToolSaveFeed:        {url: 'amazon/feed-test-tool/save-feed', method: 'POST'},

    // VINE product
    vineProductAll:             {url: 'amazon/vine-products', method: 'GET'},
    vineProductOrder:           {url: 'amazon/vine-products/{id}/order', method: 'POST'},
    vineProductValidationRules: {url: 'amazon/vine-products/validation-rules/{type}', method: 'GET'},

    // VINE order
    vineOrderAll:           {url: 'amazon/vine-orders', method: 'GET'},
    vineOrderCreateMessage: {url: 'amazon/vine-orders/{id}/messages', method: 'POST'},
    vineOrderCreateReview:  {url: 'amazon/vine-orders/{id}/reviews', method: 'POST'},

    // Order research
    orderResearchAll: {url: 'order-research/', method: 'GET'},

    orderAdjustmentsCreate:                    {url: 'orders/adjustments/{orderId}', method: 'POST'},
    orderAdjustmentsManualPayments:            {url: 'orders/adjustments/manual-payments', method: 'GET'},
    orderAdjustmentsAmazonManualProcessing:    {
        url:    'orders/adjustments/amazon-manual-amazon-processing',
        method: 'GET'
    },
    orderAdjustmentsAmazonManualProcessingOne: {
        url:    'orders/adjustments/amazon-manual-amazon-processing/{orderAdjustmentId}',
        method: 'GET'
    },
    orderAdjustmentsCreditorInfo:              {url: 'orders/adjustments/{id}/creditor-info', method: 'GET'},
    orderAdjustmentsCreditorInfoUpdate:        {url: 'orders/adjustments/{id}/creditor-info', method: 'PUT'},
    orderAdjustmentsPainFiles:                 {url: 'orders/adjustments/pain-files', method: 'GET'},
    orderAdjustmentsMarkAsPaid:                {url: 'orders/adjustments/mark-as-payed/{id}', method: 'GET'},
    orderAdjustmentsMarkAsPaidUpdate:          {url: 'orders/adjustments/mark-as-payed/{id}', method: 'PUT'},
    orderAdjustmentsSearch:                    {url: 'orders/adjustments/search/{searchHash}', method: 'GET'},
    orderAdjustmentsDetail:                    {url: 'orders/adjustments/detail/{orderId}', method: 'GET'},

    // Filters
    fbaResearchFilterAll:                 {url: 'amazon/fba-research-filters', method: 'GET'},
    fbaResearchFilterCreate:              {url: 'amazon/fba-research-filters/', method: 'POST'},
    fbaResearchFilterValidationRules:     {url: 'amazon/fba-research-filters/validation-rules/{type}', method: 'GET'},
    fbaResearchFilterGetFormulaVariables: {
        url:    'amazon/fba-research-filters/quantity-formula-variables',
        method: 'GET'
    },
    fbaResearchFilterGetOne:              {url: 'amazon/fba-research-filters/{id}', method: 'GET'},
    fbaResearchFilterValidateFormula:     {url: 'amazon/fba-research-filters/validate-formula', method: 'GET'},
    fbaResearchFilterUpdate:              {url: 'amazon/fba-research-filters/{id}', method: 'PUT'},
    fbaResearchFilterDelete:              {url: 'amazon/fba-research-filters/{id}', method: 'DELETE'},
    fbaResearchFilterProcess:             {url: 'amazon/fba-research-filters/process', method: 'POST'},

    // Order draft
    orderDraftAll:            {url: 'orders-draft/', method: 'GET'},
    orderDraftChangeQuantity: {url: 'orders-draft/{orderItemDraftId}/change-quantity', method: 'PUT'},

    // Parcel management
    parcelParkingSlotAll:             {url: 'parcel-parking-slots', method: 'GET'},
    parcelParkingSlotCreate:          {url: 'parcel-parking-slots/', method: 'POST'},
    parcelParkingSlotUpdate:          {url: 'parcel-parking-slots/{id}', method: 'PUT'},
    parcelParkingSlotDelete:          {url: 'parcel-parking-slots/{id}', method: 'DELETE'},
    parcelParkingSlotValidationRules: {url: 'parcel-parking-slots/validation-rules/{type}', method: 'GET'},
    parcelParkingSlotProcess:         {url: 'parcel-parking-slots/process', method: 'POST'},

    // Delivery plan
    deliveryPlanAll: {url: 'amazon/fba-inbound-shipments', method: 'GET'},

    // Fulfillment parcel management
    fulfillmentParcelAll:             {url: 'fulfillment-parcels', method: 'GET'},
    fulfillmentParcelCreate:          {url: 'fulfillment-parcels/', method: 'POST'},
    fulfillmentParcelUpdate:          {url: 'fulfillment-parcels/{id}', method: 'PUT'},
    fulfillmentParcelDelete:          {url: 'fulfillment-parcels/{id}', method: 'DELETE'},
    fulfillmentParcelValidationRules: {url: 'fulfillment-parcels/validation-rules/{type}', method: 'GET'},

    // Open Fulfillment parcel
    openFulfillmentParcel: {url: 'fba-shipping/open', method: 'GET'},

    // Fba Shipman
    fbaShippingResetProcessedCount:                      {
        url:    'order-items/reset-ship-man-processed-count/{orderItemId}',
        method: 'POST'
    },
    fbaShippingGetInboundShipmentInfo:                   {url: 'fba-shipping/info/{inboundShipmentId}', method: 'GET'},
    fbaShippingProcessInboundShipment:                   {
        url:    'fba-shipping/{inboundShipmentId}/process-shipment',
        method: 'POST'
    },
    fbaShippingCloseInboundShipment:                     {
        url:    'fba-shipping/{shipmentId}/close-shipment',
        method: 'POST'
    },
    fbaShippingReprintPackageLabel:                      {
        url:    'fba-shipping/{shipmentId}/reprint-label',
        method: 'POST'
    },
    fbaShippingAssignFulfillmentParcelToInboundShipment: {
        url:    'fba-shipping/{inboundShipmentId}/assign-fulfillment-parcel/{parcelId}',
        method: 'POST'
    },
    fbaShippingGetOrderItem:                             {url: 'fba-shipping/order-item/{id}', method: 'GET'},
    fbaShippingProcessLabels:                            {
        url:    'fba-shipping/{orderItemId}/process-shipping-labels',
        method: 'POST'
    },
    fbaShippingRequestInboundShipment:                   {
        url:    'fba-shipping/{orderItemId}/request-inbound-shipment',
        method: 'POST'
    },
    fbaShippingAssignOrderToInboundShipment:             {
        url:    'fba-shipping/{inboundShipmentId}/assign/{orderItemId}',
        method: 'POST'
    },
    fbaShippingReleaseOrderFromInboundShipment:          {
        url:    'fba-shipping/{inboundShipmentId}/release/{orderItemId}',
        method: 'POST'
    },

    // Order items
    orderItemsGetNotAssignedToFulfillmentProviderCount: {
        url:    'order-items/not-assigned-to-fulfillment-provider/{orderId}',
        method: 'GET'
    },
    orderItemsCustomFileUpload:                         {url: 'order-items/{id}/custom-files', method: 'POST'},

    // Fba ROQ shipman
    fbaRoqShipmanProcessOrderItem: {url: 'fba-order-items/{orderItemId}/process'},

    // RoqQueue
    roqQueueGetOrderItemsList: {url: 'roq-queues/order-items', method: 'GET'},
    roqQueueClose:             {url: 'roq-queues/{roqQueueId}/close', method: 'PUT'},
    roqQueueGetOpenList:       {url: 'roq-queues/open', method: 'GET'},

    // Shipman shipping
    manifestGetList:   {url: 'shippings/manifests', method: 'GET'},
    manifestGetParams: {url: 'shippings/manifest-params', method: 'GET'},
    manifestCreate:    {url: 'shippings/manifest', method: 'POST'},
    manifestPrint:     {url: 'shippings/manifest/print', method: 'POST'},
    manifestDownload:  {url: 'shippings/manifest/download', method: 'GET'},

    // Terminal
    getTerminalList: {url: 'shipman-terminals', method: 'GET'},

    // Suppliers
    supplierList:             {url: 'suppliers/', method: 'GET'},
    suppliersGetOne:          {url: 'suppliers/{id}', method: 'GET'},
    suppliersValidationRules: {url: 'suppliers/validation-rules/{type}', method: 'GET'},
    suppliersUpdate:          {url: 'suppliers/{id}', method: 'PUT'},
    suppliersUpdatePosition:  {url: 'suppliers/{id}/position/{position}', method: 'PUT'},

    // Stockman
    supplierOrderListWithOpenOrders: {url: 'supplier-orders/open', method: 'GET'},
    supplierParcelItemsList:         {url: 'supplier-parcel-items/{trackingNumber}', method: 'GET'},
    supplierParcelMarkAsProcessed:   {url: 'supplier-parcels/{parcelId}/mark-as-processed', method: 'POST'},
    supplierOrderItemsList:          {url: 'supplier-orders/{supplierOrderId}/supplier-order-items', method: 'GET'},
    createProductItems:              {url: 'product-items/', method: 'POST'},
    deleteProductItems:              {url: 'product-items/delete-bulk', method: 'DELETE'},
    processProductItems:             {url: 'product-items/process/in_roq_inbound', method: 'POST'},
    stockmanInventoryAll:            {url: 'inventories/', method: 'GET'},
    stockmanInventoryGetOne:         {url: 'inventories/{id}', method: 'GET'},
    inventoryGetSiblings:            {url: 'inventories/{id}/siblings', method: 'GET'},
    inventoryCreateSibling:          {url: 'inventories/{id}/siblings', method: 'POST'},
    inventoryDeleteSibling:          {url: 'inventories/{id}/siblings/{siblingId}', method: 'DELETE'},

    // Ax collection
    axCollectionList:            {url: 'ax-collection', method: 'GET'},
    axCollectionRequestNewTexts: {url: 'ax-collection/request-new-texts/{collectionId}', method: 'POST'},

    // Ax semantic events
    axSemanticEventList:            {url: 'ax-semantics/events', method: 'GET'},
    axSemanticEventCreate:          {url: 'ax-semantics/events/', method: 'POST'},
    axSemanticEventUpdate:          {url: 'ax-semantics/events/{id}', method: 'PUT'},
    axSemanticEventDelete:          {url: 'ax-semantics/events/{id}', method: 'DELETE'},
    axSemanticEventValidationRules: {url: 'ax-semantics/events/validation-rules/{type}', method: 'GET'},

    // Ax semantic buyer groups
    axSemanticBuyerGroupList:            {url: 'ax-semantics/buyer-groups', method: 'GET'},
    axSemanticBuyerGroupCreate:          {url: 'ax-semantics/buyer-groups/', method: 'POST'},
    axSemanticBuyerGroupUpdate:          {url: 'ax-semantics/buyer-groups/{id}', method: 'PUT'},
    axSemanticBuyerGroupDelete:          {url: 'ax-semantics/buyer-groups/{id}', method: 'DELETE'},
    axSemanticBuyerGroupValidationRules: {url: 'ax-semantics/buyer-groups/validation-rules/{type}', method: 'GET'},

    // Ax semantic motive description adjective
    axSemanticMotiveDescriptionAdjectiveList:            {
        url:    'ax-semantics/motive-description-adjectives',
        method: 'GET'
    },
    axSemanticMotiveDescriptionAdjectiveCreate:          {
        url:    'ax-semantics/motive-description-adjectives/',
        method: 'POST'
    },
    axSemanticMotiveDescriptionAdjectiveUpdate:          {
        url:    'ax-semantics/motive-description-adjectives/{id}',
        method: 'PUT'
    },
    axSemanticMotiveDescriptionAdjectiveDelete:          {
        url:    'ax-semantics/motive-description-adjectives/{id}',
        method: 'DELETE'
    },
    axSemanticMotiveDescriptionAdjectiveValidationRules: {
        url:    'ax-semantics/motive-description-adjectives/validation-rules/{type}',
        method: 'GET'
    },

    // Ax semantic motive description noun
    axSemanticMotiveDescriptionNounList:            {url: 'ax-semantics/motive-description-nouns', method: 'GET'},
    axSemanticMotiveDescriptionNounCreate:          {url: 'ax-semantics/motive-description-nouns/', method: 'POST'},
    axSemanticMotiveDescriptionNounUpdate:          {url: 'ax-semantics/motive-description-nouns/{id}', method: 'PUT'},
    axSemanticMotiveDescriptionNounDelete:          {
        url:    'ax-semantics/motive-description-nouns/{id}',
        method: 'DELETE'
    },
    axSemanticMotiveDescriptionNounValidationRules: {
        url:    'ax-semantics/motive-description-nouns/validation-rules/{type}',
        method: 'GET'
    },

    // Ax semantic motive styles
    axSemanticMotiveStyleList:            {url: 'ax-semantics/motive-styles', method: 'GET'},
    axSemanticMotiveStyleCreate:          {url: 'ax-semantics/motive-styles/', method: 'POST'},
    axSemanticMotiveStyleUpdate:          {url: 'ax-semantics/motive-styles/{id}', method: 'PUT'},
    axSemanticMotiveStyleDelete:          {url: 'ax-semantics/motive-styles/{id}', method: 'DELETE'},
    axSemanticMotiveStyleValidationRules: {url: 'ax-semantics/motive-styles/validation-rules/{type}', method: 'GET'},

    // Ax semantic target groups
    axSemanticTargetGroupList:            {url: 'ax-semantics/target-groups', method: 'GET'},
    axSemanticTargetGroupCreate:          {url: 'ax-semantics/target-groups/', method: 'POST'},
    axSemanticTargetGroupUpdate:          {url: 'ax-semantics/target-groups/{id}', method: 'PUT'},
    axSemanticTargetGroupDelete:          {url: 'ax-semantics/target-groups/{id}', method: 'DELETE'},
    axSemanticTargetGroupValidationRules: {url: 'ax-semantics/target-groups/validation-rules/{type}', method: 'GET'},

    // Ax semantic themes
    axSemanticThemeList:            {url: 'ax-semantics/themes', method: 'GET'},
    axSemanticThemeCreate:          {url: 'ax-semantics/themes/', method: 'POST'},
    axSemanticThemeUpdate:          {url: 'ax-semantics/themes/{id}', method: 'PUT'},
    axSemanticThemeDelete:          {url: 'ax-semantics/themes/{id}', method: 'DELETE'},
    axSemanticThemeValidationRules: {url: 'ax-semantics/themes/validation-rules/{type}', method: 'GET'},

    eventChannelSettingsOneByEventAndChannel: {
        url:    'ax-semantics/events/channel-settings/event/{eventId}/channel/{channelId}',
        method: 'GET'
    },
    eventChannelSettingsValidationRules:      {
        url:    'ax-semantics/events/channel-settings/validation-rules/{type}/channel/{channelId}',
        method: 'GET'
    },
    eventChannelSettingsCreate:               {url: 'ax-semantics/events/channel-settings/', method: 'POST'},
    eventChannelSettingsUpdate:               {url: 'ax-semantics/events/channel-settings/{id}', method: 'PUT'},

    // Channel data Dashboard
    channelDataEventBusGetDashboardTotal:        {url: 'dashboard/channel-data-event-bus/total', method: 'GET'},
    channelDataEventBusGetDashboardNotProcessed: {url: 'dashboard/channel-data-event-bus/not-processed', method: 'GET'},
    channelDataSyncQueueGetDashboardInfo:        {url: 'dashboard/channel-data-sync-queue', method: 'GET'},

    // Channel data event bus
    channelDataEventBusGetList: {url: 'dashboard/channel-data-event-bus/list', method: 'GET'},
    channelDataEventBusGetOne:  {url: 'dashboard/channel-data-event-bus/{eventBusId}', method: 'GET'},

    // Channel data event bus
    channelDataSyncQueueGetList: {url: 'dashboard/channel-data-sync-queue/list', method: 'GET'},
    channelDataSyncQueueGetOne:  {url: 'dashboard/channel-data-sync-queue/{syncQueueId}', method: 'GET'},

    // Search aliases
    searchAliasList:   {url: 'search-aliases/{objectType}/{objectId}', method: 'GET'},
    searchAliasAdd:    {url: 'search-aliases/{objectType}/{objectId}', method: 'POST'},
    searchAliasUpdate: {url: 'search-aliases/{objectType}/{objectId}', method: 'PUT'},
    searchAliasDelete: {url: 'search-aliases/{objectType}/{objectId}', method: 'DELETE'},

    // Negative keywords
    negativeKeywordList:   {url: 'negative-keywords/{objectType}/{objectId}', method: 'GET'},
    negativeKeywordAdd:    {url: 'negative-keywords/{objectType}/{objectId}', method: 'POST'},
    negativeKeywordUpdate: {url: 'negative-keywords/{objectType}/{objectId}', method: 'PUT'},
    negativeKeywordDelete: {url: 'negative-keywords/{objectType}/{objectId}', method: 'DELETE'},

    // Excluded tags
    excludedTagList:   {url: 'excluded-tags/{objectType}/{objectId}', method: 'GET'},
    excludedTagAdd:    {url: 'excluded-tags/{objectType}/{objectId}', method: 'POST'},
    excludedTagUpdate: {url: 'excluded-tags/{objectType}/{objectId}', method: 'PUT'},
    excludedTagDelete: {url: 'excluded-tags/{objectType}/{objectId}', method: 'DELETE'},

    // Stop words
    stopWordList:            {url: 'language/stop-words', method: 'GET'},
    stopWordCreate:          {url: 'language/stop-words/', method: 'POST'},
    stopWordUpdate:          {url: 'language/stop-words/{id}', method: 'PUT'},
    stopWordDelete:          {url: 'language/stop-words/{id}', method: 'DELETE'},
    stopWordValidationRules: {url: 'language/stop-words/validation-rules/{type}', method: 'GET'},

    // Language
    languageAnalyzeEntity: {url: 'language/analyze-entity', method: 'POST'},

    // Kornit settings
    kornitGetSettings:         {url: 'kornit/settings', method: 'GET'},
    kornitUpdateSettings:      {url: 'kornit/settings', method: 'PUT'},
    kornitSettingsFormChoices: {url: 'kornit/form-choices', method: 'GET'},

    // Kornit media
    kornitMediaList:            {url: 'kornit/media', method: 'GET'},
    kornitMediaCreate:          {url: 'kornit/media/', method: 'POST'},
    kornitMediaUpdate:          {url: 'kornit/media/{id}', method: 'PUT'},
    kornitMediaDelete:          {url: 'kornit/media/{id}', method: 'DELETE'},
    kornitMediaValidationRules: {url: 'kornit/media/validation-rules/{type}', method: 'GET'},

    // Kornit printers
    kornitPrinterList:            {url: 'kornit/printers/list', method: 'GET'},
    kornitPrinterCreate:          {url: 'kornit/printers/', method: 'POST'},
    kornitPrinterUpdate:          {url: 'kornit/printers/{id}', method: 'PUT'},
    kornitPrinterDelete:          {url: 'kornit/printers/{id}', method: 'DELETE'},
    kornitPrinterValidationRules: {url: 'kornit/printers/validation-rules/{type}', method: 'GET'},

    // Kornit motive settings
    kornitMotiveSettingsList:            {url: 'kornit/motive-settings', method: 'GET'},
    kornitMotiveSettingsCreate:          {url: 'kornit/motive-settings/', method: 'POST'},
    kornitMotiveSettingsUpdate:          {url: 'kornit/motive-settings/{id}', method: 'PUT'},
    kornitMotiveSettingsDelete:          {url: 'kornit/motive-settings/{id}', method: 'DELETE'},
    kornitMotiveSettingsValidationRules: {url: 'kornit/motive-settings/validation-rules/{type}', method: 'GET'},

    // Kornit measure settings
    kornitMeasureSettingsList:            {url: 'kornit/measure-settings/{rawProductId}/{printerType}', method: 'GET'},
    kornitMeasureSettingsCreate:          {url: 'kornit/measure-settings/', method: 'POST'},
    kornitMeasureSettingsUpdate:          {url: 'kornit/measure-settings/{id}', method: 'PUT'},
    kornitMeasureSettingsDelete:          {url: 'kornit/measure-settings/{id}', method: 'DELETE'},
    kornitMeasureSettingsValidationRules: {url: 'kornit/measure-settings/validation-rules/{type}', method: 'GET'},

    // Kornit raw product settings
    kornitRawProductSettingsList:            {url: 'kornit/raw-product-settings', method: 'GET'},
    kornitRawProductSettingsGetOne:          {
        url:    'kornit/raw-product-settings/raw-product/{rawProductId}/printer-type/{printerType}',
        method: 'GET'
    },
    kornitRawProductSettingsCreate:          {url: 'kornit/raw-product-settings/', method: 'POST'},
    kornitRawProductSettingsUpdate:          {url: 'kornit/raw-product-settings/{id}', method: 'PUT'},
    kornitRawProductSettingsDelete:          {url: 'kornit/raw-product-settings/{id}', method: 'DELETE'},
    kornitRawProductSettingsValidationRules: {
        url:    'kornit/raw-product-settings/validation-rules/{type}',
        method: 'GET'
    },

    // Kornit raw product color reference settings
    kornitRawProductColorReferenceSettingsList:            {
        url:    'kornit/raw-product-color-reference-settings/{rawProductId}/{printerType}',
        method: 'GET'
    },
    kornitRawProductColorReferenceSettingsCreate:          {
        url:    'kornit/raw-product-color-reference-settings/',
        method: 'POST'
    },
    kornitRawProductColorReferenceSettingsUpdate:          {
        url:    'kornit/raw-product-color-reference-settings/{id}',
        method: 'PUT'
    },
    kornitRawProductColorReferenceSettingsDelete:          {
        url:    'kornit/raw-product-color-reference-settings/{id}',
        method: 'DELETE'
    },
    kornitRawProductColorReferenceSettingsValidationRules: {
        url:    'kornit/raw-product-color-reference-settings/validation-rules/{type}',
        method: 'GET'
    },


    generalSearchTermRelationsList:                         {url: 'search-term/relations', method: 'GET'},
    generalSearchTermRelationGetOne:                        {url: 'search-term/relations/{id}', method: 'GET'},
    generalSearchTermRelationGetOneTags:                    {url: 'search-term/relations/{id}/tags', method: 'GET'},
    generalSearchTermRelationDeleteTag:                     {url: 'search-term/tags/{id}', method: 'DELETE'},
    generalSearchTermSizeTagsDelete:                        {url: 'search-term/tags/sizes', method: 'DELETE'},
    generalSearchTermTagGetRelatedSearchTermsCount:         {
        url:    'search-term/tags/relatedSearchTermsCount/{tag}',
        method: 'GET'
    },
    generalSearchTermTagDownloadCSV:                        {url: 'search-term/tags/download-csv', method: 'GET'},
    generalSearchTermRelationGetNext:                       {url: 'search-term/relations/next', method: 'GET'},
    generalSearchTermRelationGetNextTags:                   {url: 'search-term/relations/next/tags', method: 'GET'},
    generalSearchTermRelationsDone:                         {url: 'search-term/relations/{id}/done', method: 'POST'},
    generalSearchTermRelationsApprovedForAdvertising:       {
        url:    'search-term/relations/{id}/approved-for-advertising',
        method: 'POST'
    },
    generalSearchTermRelationsDisapprovedForAdvertising:    {
        url:    'search-term/relations/{id}/disapproved-for-advertising',
        method: 'POST'
    },
    generalSearchTermRelationsUserPerformance:              {
        url:    'search-term/relations/user-performance/{lastDay}',
        method: 'GET'
    },
    generalSearchTermRelationsGetUserAsinSearchTermMapList: {
        url:    'search-term/relations/adminId/{adminId}/startDateTime/{startDateTime}/endDateTime/{endDateTime}',
        method: 'GET'
    },
    generalSearchTermTagList:                               {url: 'search-term/tags/list', method: 'GET'},
    generalSearchTermTagCreate:                             {url: 'search-term/tags/', method: 'POST'},
    generalSearchTermTagCreateList:                         {url: 'search-term/tags/create-list', method: 'POST'},
    generalSearchTermTagGetInventoryList:                   {
        url:    'search-term/tags/inventory-list/{inventoryType}',
        method: 'GET'
    },
    generalSearchTermTagCreateInventoryTag:                 {url: 'search-term/tags/inventory', method: 'POST'},
    generalSearchTermTagGetUserTagList:                     {
        url:    'search-term/tags/adminId/{adminId}/startDateTime/{startDateTime}/endDateTime/{endDateTime}',
        method: 'GET'
    },

    shirtplatformFulfilmentProviderProducts:              {
        url:    'fulfillment-providers/shirtplatform/products',
        method: 'GET'
    },
    shirtplatformFulfilmentProviderAssignedColors:        {
        url:    'fulfillment-providers/shirtplatform/products/{id}/assigned-colors',
        method: 'GET'
    },
    shirtplatformFulfilmentProviderAssignedSizes:         {
        url:    'fulfillment-providers/shirtplatform/products/{id}/assigned-sizes',
        method: 'GET'
    },
    shirtplatformFulfilmentProviderSku:                   {
        url:    'fulfillment-providers/shirtplatform/products/{id}/sku',
        method: 'GET'
    },
    shirtplatformFulfilmentProviderInventoryUpdateCreate: {
        url:    'fulfillment-providers/shirtplatform/products/{id}/inventory-mapping',
        method: 'POST'
    },

    addressTypesAll:              {url: 'address-types', method: 'GET'},
    addressTypesGetOne:           {url: 'address-types/{id}', method: 'GET'},
    addressTypesUpdate:           {url: 'address-types/{id}', method: 'PUT'},
    addressTypesCreate:           {url: 'address-types/', method: 'POST'},
    addressTypesDelete:           {url: 'address-types/{id}', method: 'DELETE'},
    addressTypesValidationRules:  {url: 'address-types/validation-rules/{type}', method: 'GET'},
    addressTypesValidateOnCreate: {url: 'address-types/validate', method: 'POST'},
    addressTypesValidateOnUpdate: {url: 'address-types/validate/{id}', method: 'PUT'},

    packingsAll:              {url: 'packings', method: 'GET'},
    packingsGetOne:           {url: 'packings/{id}', method: 'GET'},
    packingsUpdate:           {url: 'packings/{id}', method: 'PUT'},
    packingsCreate:           {url: 'packings/', method: 'POST'},
    packingsDelete:           {url: 'packings/{id}', method: 'DELETE'},
    packingsValidationRules:  {url: 'packings/validation-rules/{type}', method: 'GET'},
    packingsValidateOnCreate: {url: 'packings/validate', method: 'POST'},
    packingsValidateOnUpdate: {url: 'packings/validate/{id}', method: 'PUT'},

    carriersAll:              {url: 'carriers', method: 'GET'},
    carriersGetOne:           {url: 'carriers/{id}', method: 'GET'},
    carriersUpdate:           {url: 'carriers/{id}', method: 'PUT'},
    carriersCreate:           {url: 'carriers/', method: 'POST'},
    carriersDelete:           {url: 'carriers/{id}', method: 'DELETE'},
    carriersValidationRules:  {url: 'carriers/validation-rules/{type}', method: 'GET'},
    carriersValidateOnCreate: {url: 'carriers/validate', method: 'POST'},
    carriersValidateOnUpdate: {url: 'carriers/validate/{id}', method: 'PUT'},

    countriesAll:              {url: 'countries', method: 'GET'},
    countriesGetOne:           {url: 'countries/{id}', method: 'GET'},
    countriesUpdate:           {url: 'countries/{id}', method: 'PUT'},
    countriesCreate:           {url: 'countries/', method: 'POST'},
    countriesDelete:           {url: 'countries/{id}', method: 'DELETE'},
    countriesValidationRules:  {url: 'countries/validation-rules/{type}', method: 'GET'},
    countriesValidateOnCreate: {url: 'countries/validate', method: 'POST'},
    countriesValidateOnUpdate: {url: 'countries/validate/{id}', method: 'PUT'},

    countryPackingsAll:              {url: 'countries/{countryId}/packings', method: 'GET'},
    countryPackingsGetOne:           {url: 'countries/{countryId}/packings/{id}', method: 'GET'},
    countryPackingsUpdate:           {url: 'countries/{countryId}/packings/{id}', method: 'PUT'},
    countryPackingsCreate:           {url: 'countries/{countryId}/packings/', method: 'POST'},
    countryPackingsDelete:           {url: 'countries/{countryId}/packings/{id}', method: 'DELETE'},
    countryPackingsValidationRules:  {url: 'countries/{countryId}/packings/validation-rules/{type}', method: 'GET'},
    countryPackingsValidateOnCreate: {url: 'countries/{countryId}/packings/validate', method: 'POST'},
    countryPackingsValidateOnUpdate: {url: 'countries/{countryId}/packings/validate/{id}', method: 'PUT'},

    shippingTypesAll:              {url: 'shipping-types', method: 'GET'},
    shippingTypesGetOne:           {url: 'shipping-types/{id}', method: 'GET'},
    shippingTypesUpdate:           {url: 'shipping-types/{id}', method: 'PUT'},
    shippingTypesCreate:           {url: 'shipping-types/', method: 'POST'},
    shippingTypesDelete:           {url: 'shipping-types/{id}', method: 'DELETE'},
    shippingTypesValidationRules:  {url: 'shipping-types/validation-rules/{type}', method: 'GET'},
    shippingTypesValidateOnCreate: {url: 'shipping-types/validate', method: 'POST'},
    shippingTypesValidateOnUpdate: {url: 'shipping-types/validate/{id}', method: 'PUT'},
    shippingTypesUpdatePosition:   {url: 'shipping-types/{id}/position/{position}', method: 'PUT'},


    shippingTypeCountryFulfillmentProvidersAll:              {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers',
        method: 'GET'
    },
    shippingTypeCountryFulfillmentProvidersGetOne:           {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/{id}',
        method: 'GET'
    },
    shippingTypeCountryFulfillmentProvidersUpdate:           {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/{id}',
        method: 'PUT'
    },
    shippingTypeCountryFulfillmentProvidersCreate:           {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/',
        method: 'POST'
    },
    shippingTypeCountryFulfillmentProvidersDelete:           {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/{id}',
        method: 'DELETE'
    },
    shippingTypeCountryFulfillmentProvidersValidationRules:  {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/validation-rules/{type}',
        method: 'GET'
    },
    shippingTypeCountryFulfillmentProvidersValidateOnCreate: {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/validate',
        method: 'POST'
    },
    shippingTypeCountryFulfillmentProvidersValidateOnUpdate: {
        url:    'shipping-types/{shippingTypeId}/country-fulfillment-providers/validate/{id}',
        method: 'PUT'
    },


    // Permission
    permissionAvailableEndpoints: {url: 'available-endpoints', method: 'GET'},
};


@Injectable()
export class ApiRouterService {
    static getConfig(): IAppConfig {
        return APP_DI_CONFIG;
    }

    /**
     * Ger route by route name
     * @param name
     * @param params
     * @returns {any|string}
     */
    static getAdminRoute(name, params: any = {}) {
        const url         = Object.keys(API_ROUTES[name]).includes('url') ? API_ROUTES[name]['url'] : API_ROUTES[name];
        let route: string = ApiRouterService.getConfig().API_ADMIN_URL + url || '';

        // Replace params in route if params is not empty
        if (Object.keys(params).length) {
            for (const param in params) {
                route = route.replace(`{${param}}`, params[param]);
            }
        }

        return route
    }

}
